<template>
	<div>
    <ListHeader entity-name="article" router-name="ArticleNew"></ListHeader>
    <Modal model="article" @confirm-deletion="deleteRecord"/>
		<div class="default-according style-1 faq-accordion default-accordion" id="accordionoc">
			<div class="container-fluid">
				<div class="row">
					<div class="col-xl-3 xl-40">
            <FilterCard :search-fields="searchFields"
                        :submit-button="searchButton"
                        @searchRecords="search">
            </FilterCard>
					</div>
					<div class="col-xl-9 xl-60">
            <SharedCard :records="records"
                        model-name="Article"
                        :pages="pages"
                        :paginate="paginate"
                        :send-record="sendRecord"
                        :publish="publish"
                        :unpublish="unpublish"
                        :options="options">
            </SharedCard>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import ListsMixin from 'mixins/ListsMixin';
import ContentListsMixin from 'mixins/ContentListsMixin';

export default {
  name:'ArticleList',
  data() {
    return {
      modelName: 'article'
    };
  },
  computed: {
    ...mapGetters({
      records: 'articles',
      tagsList: 'tagsList',
      currentUser: 'currentUser'
    })
  },
  methods: {
    ...mapActions({
      getRecords: 'getArticles',
      destroyRecord: 'destroyArticle',
      publishRecord: 'publishArticle',
      unpublishRecord: 'unpublishArticle',
      searchTags: 'searchTags'
    })
  },
  mixins: [ListsMixin, ContentListsMixin]
};
</script>
