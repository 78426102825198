<template>
  <div>
    <b-card class="m-b-20" no-body v-for="(record, id) in records.items" :key="id">
      <CardHeader :id="id" :model-name="modelName" :record="record" @publish="publish" @unpublish="unpublish"
                  @sendRecord="sendRecord"></CardHeader>
      <ArticleCardBody v-if="modelName === 'Article'" :id="id" :article="record"></ArticleCardBody>
      <MediaCardBody v-else :id="id" :model-name="modelName.toLowerCase()" :record="record"></MediaCardBody>
    </b-card>
    <div class="pagination" v-if="countNotZero" >
      <pagination :options="options" :pages="pages" @paginate="paginate"/>
    </div>
    <div class="text-center" v-else>
      {{ $t('messages.error.noContent') }}
    </div>
  </div>
</template>

<script>
import ArticleCardBody from 'pages/article/article-card-body';
import MediaCardBody from './MediaCardBody';
import CardHeader from './CardHeader';

export default {
  props: {
    modelName: String,
    records: Object,
    publish: Function,
    unpublish: Function,
    sendRecord: Function,
    pages: Number,
    paginate: Function,
    options: Object
  },
  computed: {
    countNotZero() {
      return this.records.totalCount > 0;
    }
  },
  components: {
    ArticleCardBody,
    MediaCardBody,
    CardHeader
  }
};
</script>
