<template>
  <b-collapse :id="`${id}`" role="tabpanel">
    <div class="card">
      <b-card-body>
        <p> {{ article.truncated_body }} </p>
        <span v-for="(tag, id) in article.tags" :key="id" class="tag-item m-r-10">
                    {{ tag }}
                    </span>
      </b-card-body>
    </div>
  </b-collapse>
</template>

<script>
export default {
  props: {
    id: Number,
    article: Object
  }
};
</script>
