<template>
  <b-collapse :id="`${id}`" role="tabpanel">
    <div class="card">
      <b-card-body>
        <div class="row">
          <span class="col-12 row p-0 m-0">
            <p class="col-3 p-r-0 mb-2">
              <strong>{{ $t('models.' + modelName + '.attributes.url') }}</strong>
            </p>
            <a class="col-9 mb-2" :href="record.url">{{ record.url }}</a>
          </span>
          <p class="col-3 p-r-0 mb-2">
            <strong>{{ $t('models.' + modelName + '.attributes.description') }}</strong>
          </p>
          <p class="col-12 mb-10" v-text="record.truncated_description"></p>
          <div class="col-12">
            <span v-for="(tag, id) in record.tags" :key="id" class="tag-item m-r-10">
              {{ tag }}
            </span>
          </div>
        </div>
      </b-card-body>
    </div>
  </b-collapse>
</template>

<script>
export default {
  props: {
    id: Number,
    modelName: String,
    record: Object
  }
};
</script>
