import SharedCard from '../components/shared/SharedCard';

export default {
  data() {
    return {
      searchFields: {
        title: {
          type: 'text',
          key: 'title',
          value: '',
          label: this.$t('models.content.attributes.title'),
          classes: 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-0',
          valid: true
        },
        tags: {
          type: 'searchableSelect',
          allowMultiple: true,
          taggable: false,
          key: 'tags',
          value: [],
          searchData: [],
          autocompleteAction: this.suggestTags,
          label: this.$t('models.content.attributes.tags'),
          classes: 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-0',
          valid: true
        },
        authoredByCurUser: {
          type: 'check',
          key: 'authorId',
          value: '',
          label: this.$t('actions.createdByMe'),
          classes: 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-0',
          valid: true
        }
      }
    };
  },
  methods: {
    suggestTags(val) {
      this.searchTags({...this.options, filterBy: { key: val, model: 'SearchableItem' }}).then(() => {
        this.searchFields.tags.searchData = this.tagsList.items;
      });
    },
    getFilters(data) {
      return {
        authorId: data.author_id? this.currentUser.id : null,
        tags: data.tags ? data.tags : [],
        orAttributes: { title: data.title }
      };
    }
  },
  components: {
    SharedCard
  }
};
